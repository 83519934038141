import { fetcher } from "utils";
import { API_ROUTES, GetBaseUrl } from "utils/constants/routes";
import { TruckingVehicle } from "types/truckingDBFields";
import { CreateVehiclesResponse } from "types/salesforce";
import { api } from "utils/api";

const DEFAULT_BASE_API_URL = GetBaseUrl();

export const getVehicles = (leadId: string) => {
  return fetcher(`${API_ROUTES.SF_TRUCKING_VEHICLES}/${leadId}`, "GET");
};

export const getVehiclesServerSide = (leadId: string) => {
  return fetcher(
    `${DEFAULT_BASE_API_URL}${API_ROUTES.SF_TRUCKING_VEHICLES}/${leadId}`
  );
};

export const createVehicle = (leadId: string, data: TruckingVehicle) => {
  return fetcher(`${API_ROUTES.SF_TRUCKING_VEHICLES}/${leadId}`, "POST", data);
};

export const createVehicles = async (
  leadId: string,
  data: TruckingVehicle[]
) => {
  return await api<TruckingVehicle[], CreateVehiclesResponse>(
    `${API_ROUTES.SF_TRUCKING_VEHICLES}/${leadId}`,
    "POST",
    data
  );
};

export const deleteVehicle = (leadId: string, vin: string) => {
  return fetcher(
    `${API_ROUTES.SF_TRUCKING_VEHICLES}/${leadId}/${vin}`,
    "DELETE"
  );
};
