import React, { useEffect, useState } from "react";
import { useRouter } from "next/router";
import { getLead } from "services/salesforce/trucking/leads";
import { getVehicles } from "services/salesforce/trucking/vehicles";
import { getDrivers } from "services/salesforce/trucking/drivers";
import { getAccount } from "services/salesforce/trucking/accounts";

export const ApplicationStateContext = React.createContext(undefined);

export function ApplicationProvider({ children }) {
  const router = useRouter();
  const { leadId } = router.query;
  const [lead, setLead] = useState();
  const [accountInfo, setAccountInfo] = useState();
  const [account, setAccount] = useState();
  const [step1, setStep1] = useState();
  const [opportunity, setOpportunity] = useState();
  const [vehicles, setVehicles] = useState();
  const [drivers, setDrivers] = useState();
  const [loading, setLoading] = useState(false);

  const fetchLead = async () => {
    setLoading(true);
    try {
      const leadData = await getLead(leadId);
      setLead(leadData);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const fetchAccount = async () => {
    setLoading(true);
    try {
      const accountData = await getAccount(leadId);
      if (accountData) {
        setAccount(accountData);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  const fetchVehicles = async () => {
    setLoading(true);
    try {
      const result = await getVehicles(leadId);
      setVehicles(result);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  const fetchDrivers = async () => {
    setLoading(true);
    try {
      const result = await getDrivers(leadId);
      setDrivers(result);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  useEffect(() => {
    if (leadId) {
      fetchLead();
      fetchAccount();
      fetchVehicles();
      fetchDrivers();
    }
  }, [leadId]);

  return (
    <ApplicationStateContext.Provider
      value={{
        leadId,
        lead,
        opportunity,
        accountInfo,
        account,
        setAccountInfo,
        setLead,
        step1,
        setStep1,
        vehicles,
        fetchVehicles,
        drivers,
        loading,
        setLoading,
      }}
    >
      {children}
    </ApplicationStateContext.Provider>
  );
}

export function useApplicationState() {
  const context = React.useContext(ApplicationStateContext);
  if (context === undefined) {
    const err = new Error(
      "useApplicationState must be used within an ApplicationProvider"
    );
    throw err;
  }
  return context;
}
