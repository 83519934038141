import React, { FC } from "react";
import clsx from "clsx";
import Icon from "../Icon";
import Link from "next/link";
import Image from "next/image";

type HeaderProps = {
  hasNewUI?: boolean;
};
const Header: FC<HeaderProps> = ({ hasNewUI }) => {
  return (
    <div className="w-full h-16 bg-white shadow">
      <div
        className={clsx(
          "max-w-6xl h-full mx-auto px-4 sm:px-6 lg:px-8 flex items-center",
          { ["justify-center"]: hasNewUI }
        )}
      >
        <Link href="https://getaddify.com/">
          <Image
            alt="Logo"
            src="/AddifyLogo_Transparent.png"
            width={150}
            height={42}
          />
        </Link>
      </div>
    </div>
  );
};

export default Header;
