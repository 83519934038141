import { UseMutateFunction, useMutation, useQueryClient } from "react-query";
import { IAppContext } from "types";
import { api } from "utils/api";
import { API_ROUTES } from "utils/constants/routes";
import { UserContinuityResponse } from "pages/api/continuity/[id]";

type CreateUserContextProps = () => {
  mutate: UseMutateFunction<UserContinuityResponse, unknown, void, void>;
};

const useGetUserContinuity: CreateUserContextProps = () => {
  const queryClient = useQueryClient();

  const { mutate } = useMutation(
    () => {
      const userId = queryClient.getQueryData<IAppContext["id"]>("id");

      if (!userId) {
        let emptyResponse: UserContinuityResponse = {
          session: null,
          status: {
            success: true,
            message: "No user id, so not checking continuity.",
          },
        };

        return Promise.resolve(emptyResponse);
      }

      return api<undefined, UserContinuityResponse>(
        API_ROUTES.USER_CONTINUITY + "/" + userId,
        "GET"
      );
    },
    {
      onMutate: () => {},
    }
  );

  return { mutate };
};

export default useGetUserContinuity;
