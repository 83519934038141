export const enum SEO_TITLE {
  BASE = "GetAddify, Get your Free Quote",
  CONT_COV = "GetAddify - Vehicle Coverage",
  ORP = "GetAddify - Vehicle Coverage - Off Rental Policy",
  TRUCKING = "GetAddify Trucking",
  LULASAFE = " ",
  GAIL = " ",
}

export const enum SEO_DESCRIPTION {
  BASE = "GetAddify, Get your Free Quote",
  CONT_COV = "What type of vehicle coverage are you looking for?",
  ORP = "What type of vehicle coverage are you looking for? We support Getaround.",
  TRUCKING = " ",
  LULASAFE = " ",
  GAIL = " ",
}
