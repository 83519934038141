import { ITruckingAccount } from "types/trucking";
import { fetcher } from "utils";
import { api } from "utils/api";
import { API_ROUTES, GetBaseUrl } from "utils/constants/routes";

// Replace when implementing DB
const DEFAULT_BASE_API_URL = GetBaseUrl();

export const getAccount = (leadId: string) => {
  return fetcher(`/api/salesforce/v2/trucking/account/${leadId}`, "GET");
};

export const getAccountServerSide = (leadId: string) => {
  return fetcher(
    `${DEFAULT_BASE_API_URL}${API_ROUTES.SF_TRUCKING_ACCOUNT}/${leadId}`
  );
};

//End Replace

export const updateAccount = (leadId: string, data: any) => {
  return fetcher(`/api/salesforce/v2/trucking/account/${leadId}`, "POST", data);
};

export const sendReviewAccountMessage = async (
  leadId: string,
  account: ITruckingAccount,
  messageType: string,
  reviewReason: string
) => {
  await api(API_ROUTES.TRUCKING_SLACK_MESSAGE, "POST", {
    leadId,
    account,
    messageType,
    reviewReason,
  });
};
