import { fetcher } from "utils";
import { API_ROUTES, GetBaseUrl } from "utils/constants/routes";
import { TruckingDriver } from "types/truckingDBFields";

const DEFAULT_BASE_API_URL = GetBaseUrl();

export const getDrivers = (leadId: string) => {
  return fetcher(`${API_ROUTES.SF_TRUCKING_DRIVERS}/${leadId}`, "GET");
};

export const getDriversServerSide = (leadId: string) => {
  return fetcher(
    `${DEFAULT_BASE_API_URL}${API_ROUTES.SF_TRUCKING_DRIVERS}/${leadId}`
  );
};

export const createDriver = (leadId: string, data: TruckingDriver) => {
  return fetcher(`${API_ROUTES.SF_TRUCKING_DRIVERS}/${leadId}`, "POST", data);
};

export const deleteDriver = (leadId: string, licenseNumber: string) => {
  return fetcher(
    `${API_ROUTES.SF_TRUCKING_DRIVERS}/${leadId}/${licenseNumber}`,
    "DELETE"
  );
};
