import clsx from "clsx";
import { FC, ReactNode } from "react";

type CoverageTypeAlertProps = {
  title: string | ReactNode;
  children?: ReactNode;
};

const CoverageTypeAlert: FC<CoverageTypeAlertProps> = ({ title, children }) => {
  return (
    <div
      className={clsx(
        "p-4",
        "bg-white",
        "text-center",
        "flex",
        "flex-col",
        "justify-start",
        "gap-4",
        "items-center",
        "rounded-2xl",
        "border",
        "col-span-2"
      )}
    >
      <h4 className={clsx("text-lg", "font-bold")}>{title}</h4>
      {children}
    </div>
  );
};

export default CoverageTypeAlert;
