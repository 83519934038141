import clsx from "clsx";
import English from "locale/english.json";

const IndeterminateLoading = (size = 300, label = English.states.loading) => {
  return (
    <div
      className={clsx(
        "w-full",
        "h-full",
        "flex",
        "items-center",
        "justify-center"
      )}
      style={{ height: `${size}px` }}
    >
      <div className={clsx("relative")}>
        <div
          style={{ width: 200 }}
          className={clsx(
            "w-48",
            "h-48",
            "border-4",
            "border-purple-300",
            "border-solid",
            "rounded-full",
            "animate-spin"
          )}
        />
        <div
          style={{ height: 200 }}
          className={clsx(
            "absolute",
            "top-0",
            "w-48",
            "h-48",
            "border-4",
            "border-blue-300",
            "border-solid",
            "rounded-full",
            "animate-spin"
          )}
        />
        <div
          className={clsx(
            "absolute",
            "top-0",
            "left-0",
            "bottom-0",
            "right-0",
            "border-4",
            "border-orange-400",
            "border-solid",
            "rounded-full",
            "opacity-25"
          )}
        />
        <p
          className={clsx(
            "absolute",
            "w-full",
            "top-20",
            "left-0",
            "text-center",
            "text-2xl"
          )}
        >
          {label}
        </p>
      </div>
    </div>
  );
};

export default IndeterminateLoading;
