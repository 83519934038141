import { UseMutateFunction, useMutation, useQueryClient } from "react-query";
import {
  InitialUserRequest,
  InitializeUserResponse,
} from "pages/api/user/initialize";
import { IAppContext } from "types";
import { api } from "utils/api";
import { CoverageType } from "utils/constants/generic";
import { API_ROUTES } from "utils/constants/routes";
import { UtmParams } from "types/generic";

type UserInformation = {
  coverageType?: CoverageType;
  referrer?: string;
  leadSourceDetail?: string;
  utmParams?: UtmParams;
  growsurfParticipantId?: string;
};

type CreateUserContextProps = (
  coverageType?: CoverageType,
  referrer?: string,
  leadSourceDetail?: string,
  utmParams?: UtmParams,
  growsurfParticipantId?: string
) => {
  mutate: UseMutateFunction<
    InitializeUserResponse,
    unknown,
    UserInformation | undefined,
    void
  >;
  isLoading: boolean;
};

const useCreateUserContext: CreateUserContextProps = (
  coverageType,
  referrer,
  leadSourceDetail,
  utmParams,
  growsurfParticipantId
) => {
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation(
    (data?: UserInformation) => {
      const existingUserId = queryClient.getQueryData<IAppContext["id"]>("id");

      return api<InitialUserRequest, InitializeUserResponse>(
        API_ROUTES.INITIALIZE_USER,
        "POST",
        {
          userId: existingUserId || undefined,
          referrer: data?.referrer || referrer,
          coverageType: data?.coverageType || coverageType,
          leadSourceDetail: data?.leadSourceDetail || leadSourceDetail,
          utmParams: data?.utmParams || utmParams,
          growsurfParticipantId:
            data?.growsurfParticipantId || growsurfParticipantId,
        }
      );
    },
    {
      onMutate: (coverageType) => {
        queryClient.setQueryData(
          "coverage",
          coverageType || CoverageType.ContinuousCoverage
        );
      },
      onSuccess: ({ userId }) => {
        const existingUserId =
          queryClient.getQueryData<IAppContext["id"]>("id");

        if (!existingUserId && userId) {
          queryClient.setQueryData("id", userId);
        }
      },
    }
  );

  return { mutate, isLoading };
};

export default useCreateUserContext;
