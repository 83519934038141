import { useState } from "react";
import clsx from "clsx";
import Link from "next/link";
import { useRouter } from "next/router";
import coverageImg from "assets/icons/continuous-coverage.svg";
import offRentalImg from "assets/icons/off-rental-policy.svg";
import contactImg from "assets/icons/contact-us.svg";
import {
  BASE_AUTH_PUBLIC,
  BASE_LULA,
  EXTERNAL_ROUTES,
  ROUTES,
} from "utils/constants/routes";
import CoverageTypeCard from "./CoverageTypeCard";
import { useQueryClient } from "react-query";
import useCreateUserContext from "hooks/useCreateUserContext";
import { IAppContext } from "types";
import {
  GaEvent,
  GaActions,
  GaCategories,
  SetUserFromUser,
} from "utils/analytics";
import { CoverageType } from "utils/constants/generic";
import { CurrentSelfOnboardingSession } from "types/context";
import { NextSeo } from "next-seo";
import { SEO_DESCRIPTION, SEO_TITLE } from "utils/seo";
import { Button } from "@getaddify/lux";
import CoverageTypeAlert from "./CoverageTypeAlert";
import CoverageSwitchModal from "./CoverageSwitchModal";
import { UtmParams } from "types/generic";

type CoverageTypeCheckerProps = {
  session: CurrentSelfOnboardingSession | null;
};

const CoverageTypeChecker = ({ session }: CoverageTypeCheckerProps) => {
  const { push, query } = useRouter();
  const [changeModalSettings, setChangeModalSettings] = useState({
    isOpen: false,
    isLoading: false,
    desiredCoverage: CoverageType.ContinuousCoverage,
    existingCoverage: CoverageType.ContinuousCoverage,
  });
  const queryClient = useQueryClient();
  const existingUserId = queryClient.getQueryData<IAppContext["id"]>("id");
  const existingStoredCoverageType =
    queryClient.getQueryData<IAppContext["coverageType"]>("coverageType");

  const coverage = query.coverage as CoverageType | undefined;
  const { mutate, isLoading } = useCreateUserContext(coverage);

  const existingAccountId =
    session?.appContext?.id ||
    session?.appContext?.account?.accountId ||
    existingUserId;
  const existingCoverageType =
    session?.appContext?.coverageType || existingStoredCoverageType;

  const handleOpenModal = () => {
    GaEvent(GaActions.Contact_Us_Clicked, GaCategories.Landing_Page);

    window.open(`${BASE_LULA}/contact-us`);
  };

  const handleContinuousCoverage = () => {
    if (
      !checkExistingUserCanContinue(
        CoverageType.ContinuousCoverage,
        existingAccountId,
        existingCoverageType
      )
    ) {
      return;
    }

    goToContinuousCoverage();
  };

  const goToContinuousCoverage = () => {
    if (existingAccountId) {
      push(ROUTES.CONTINUE + "/" + existingAccountId);
    } else {
      queryClient.setQueryData("coverage", CoverageType.ContinuousCoverage);
      const referrer = (query.referrer || query.utm_source) as string;
      const leadSourceDetail = `${query.utm_source as string}|${query.utm_term as string
        }`;

      let utmParams: UtmParams | undefined;
      let growsurfParticipantId: string | undefined;

      if (query.utm_source) {
        utmParams = {
          utmSource: (query.utm_source || "") as string,
          utmMedium: (query.utm_medium || "") as string,
          utmCampaign: (query.utm_campaign || "") as string,
          utmTerm: (query.utm_term || "") as string,
          utmContent: (query.utm_content || "") as string,
        };
      }

      if (query.grsf) {
        growsurfParticipantId = query.grsf as string;
      }

      mutate(
        {
          coverageType: CoverageType.ContinuousCoverage,
          referrer,
          leadSourceDetail,
          utmParams,
          growsurfParticipantId,
        },
        {
          onSuccess: () => {
            const userId = queryClient.getQueryData<IAppContext["id"]>("id");

            if (userId) {
              push(ROUTES.PERSONAL_INFORMATION + "/" + userId);
            }
          },
        }
      );
    }
  };

  const handleOrpClick = () => {
    if (
      !checkExistingUserCanContinue(
        CoverageType.ORP,
        existingAccountId,
        existingCoverageType
      )
    ) {
      return;
    }

    if (existingAccountId) {
      push(ROUTES.CONTINUE + "/" + existingAccountId);
    } else {
      push(ROUTES.OFF_RENTAL_POLICY);
    }
  };

  const handleContinuityClick = () => {
    if (!session) {
      return;
    }

    try {
      queryClient.setQueryData("user", session.appContext.user);
      queryClient.setQueryData("account", session.appContext.account);
      queryClient.setQueryData("quote", session.appContext.quote);
      queryClient.setQueryData("coverageType", session.appContext.coverageType);
      queryClient.setQueryData(
        "salesforceLead",
        session.appContext.salesforceLead
      );
    } catch (e) {
      console.log(e);
    }

    SetUserFromUser(
      session.appContext.user,
      session.appContext.coverageType || CoverageType.ContinuousCoverage
    );

    if (session.appContext.id) {
      push(session.route + "/" + session.appContext.id);
    } else if (session.appContext.account?.accountId) {
      push(session.route + "/" + session.appContext.account?.accountId);
    } else if (existingUserId) {
      push(session.route + "/" + existingUserId);
    }
  };

  const checkExistingUserCanContinue = (
    desiredCoverage: CoverageType,
    id?: string,
    existingCoverage?: CoverageType
  ) => {
    //If no user or user is same coverageType, we can continue
    if (!id || !existingCoverage || existingCoverage === desiredCoverage) {
      return true;
    }
    //If that user is using a different product we need to change a lot of things
    //open modal for user to input data to be able to change and to confirm they want to change
    setChangeModalSettings({
      isOpen: true,
      isLoading: false,
      desiredCoverage,
      existingCoverage,
    });

    return false;
  };

  return (
    <>
      <NextSeo
        title={SEO_TITLE.CONT_COV}
        description={SEO_DESCRIPTION.CONT_COV}
      // noindex={isStage ? true : false}
      // nofollow={isStage ? true : false}
      />
      <div className={clsx("container", "max-w-5xl", "mt-12", "px-4")}>
        <h1 className={clsx("text-2xl font-bold text-center")}>
          What type of vehicle coverage are you looking for?
        </h1>
        <div
          className={clsx(
            "grid",
            "lg:grid-cols-2",
            "my-12",
            "gap-4",
            "lg:gap-12"
          )}
        >
          {existingAccountId && (
            <CoverageTypeAlert title="Continue where you left off?">
              <Button
                onClick={handleContinuityClick}
                type={!existingAccountId ? "secondary" : "primary"}
                className={clsx("block", "w-40", "mt-auto", "mx-auto", {
                  "animate-bounce": !changeModalSettings.isOpen,
                })}
              >
                Continue
              </Button>
            </CoverageTypeAlert>
          )}
          <CoverageTypeCard
            img={coverageImg}
            title={
              <span>
                <p className={clsx("text-xl", "mb-3")}>Continuous Coverage</p>
                <p className={clsx("font-normal", "mb-3")}>
                  For Private Rental Fleets
                </p>
                <p className="underline">Currently Taking Waitlist Customers</p>
              </span>
            }
            alt={"Continuous Coverage"}
            description={
              "A+ rated, industry standard coverage that fits your business needs + driver risk assessment tools."
            }
          >
            <Button
              onClick={handleContinuousCoverage}
              type={existingAccountId ? "secondary" : "primary"}
              className={clsx("block", "w-40", "mt-auto", "mx-auto")}
            >
              Join the Waitlist
            </Button>
          </CoverageTypeCard>
          <CoverageTypeCard
            img={offRentalImg}
            title={
              <span>
                <p className={clsx("mb-3", "text-xl")}>Off Rental Policy</p>
                <p className={clsx("font-normal", "mb-3")}>
                  For Car Sharing hosts (like Getaround)
                </p>
                <p className="underline">Currently Taking Waitlist Customers</p>
              </span>
            }
            alt={"Off Rental Policy"}
            description={
              "Coverage when your vehicles are getting repairs, filling up on gas or whenever its off rent."
            }
          >
            <Button
              onClick={handleOrpClick}
              type={existingAccountId ? "secondary" : "primary"}
              className={clsx("block", "w-40", "mt-auto", "mx-auto")}
            >
              Join the Waitlist
            </Button>
          </CoverageTypeCard>
          {/*<CoverageTypeCard
            img={contactImg}
            title={"Contact Us"}
            description={
              "Not sure where to start or have questions? Shoot us a message!"
            }
          >
            <Button
              onClick={handleOpenModal}
              type={existingAccountId ? "secondary" : "primary"}
              className={clsx("block", "w-40", "mt-auto", "mx-auto")}
            >
              Contact Us
            </Button>
          </CoverageTypeCard>*/}
        </div>
        {!existingAccountId && (
          <p className={clsx("text-center", "pb-8")}>
            <>
              <span>Already have an account?</span>
              <Link
                href={`${BASE_AUTH_PUBLIC}${EXTERNAL_ROUTES.LOGIN}`}
                className="ml-4 link"
              >
                Sign In
              </Link>
            </>
          </p>
        )}
      </div>
      {existingAccountId && (
        <CoverageSwitchModal
          accountId={existingAccountId}
          {...changeModalSettings}
          onClose={() => {
            setChangeModalSettings({ ...changeModalSettings, isOpen: false });
          }}
          setIsLoading={(loading: boolean) => {
            setChangeModalSettings({
              ...changeModalSettings,
              isLoading: loading,
            });
          }}
        />
      )}
    </>
  );
};

export default CoverageTypeChecker;
