import { ISalesforceLead } from "types";
import { ISalesforceCreateLeadResponse } from "types/salesforce";
import { ITruckingLead } from "types/trucking";
import { fetcher } from "utils";
import { API_ROUTES, GetBaseUrl } from "utils/constants/routes";

// Replace when implementing DB
const DEFAULT_BASE_API_URL = GetBaseUrl();

export const getLead = (leadId: string) => {
  return fetcher(`${API_ROUTES.SF_TRUCKING_LEAD}/${leadId}`);
};

export const getLeadServerSide = (leadId: string) => {
  return fetcher(
    `${DEFAULT_BASE_API_URL}${API_ROUTES.SF_TRUCKING_LEAD}/${leadId}`
  );
};

//End Replace

export const createLead = (
  data: ITruckingLead
): Promise<ISalesforceCreateLeadResponse> => {
  return fetcher(API_ROUTES.SF_TRUCKING_LEAD, "POST", data);
};

export const updateLead = (leadId: string, data: ISalesforceLead) => {
  return fetcher(`${API_ROUTES.SF_TRUCKING_LEAD}/${leadId}`, "PUT", data);
};
